<template>
  <b-card class="m-0">
    <div class="mx-1 mb-1 d-flex justify-content-between align-items-center">
      <h4 class="bold">Requirements</h4>
      <b-button size="sm" variant="outline-primary" pill>
        Paid Consultation
      </b-button>
    </div>
    <b-row class="mx-1">
      <b-col class="border rounded-lg px-2 py-1">
        <div class="mb-1">
          <h6 class=" ">Industry</h6>
          <h5 class="bold">FinTech, Designing, Technology</h5>
        </div>
        <div class="mb-1">
          <h6 class=" ">Function</h6>
          <h5 class="bold">Operation</h5>
        </div>
        <div class="mb-1">
          <h6 class=" ">Sub Function</h6>
          <h5 class="bold">Financial Operation</h5>
        </div>
        <div class="mb-1">
          <h6 class=" ">Key Skills</h6>
          <h5 class="bold">MS Excel, PPT, SAP Accounting Software</h5>
        </div>
        <div class="mb-1">
          <h6 class="">Tenure</h6>
          <h5 class="bold">12 March, 2022 - 23 March, 2023</h5>
        </div>
        <div class="mb-1">
          <h6 class="">Work Days</h6>
          <h5 class="bold">5 Days in a Week</h5>
        </div>
        <div class="mb-1">
          <h6 class="">Preffered Location</h6>
          <h5 class="bold">Singapure, Remote</h5>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { email, required } from '@validations';
import { BButton, BCard, BCol, BRow } from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCol,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      resident: [],
      required,
      email,
      selectCountryCode: '',
      organization: '',
      selectIndustry: [],
      countryCodeOptions: [
        { value: '+61', text: 'Australia(+61)' },
        { value: '+689', text: 'French(+689)' },
      ],
      industryOptions: [{ title: 'Option 1' }, { title: 'Option 2' }],
    };
  },
  computed: {},
};
/* eslint-disable global-require */
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
</style>

<template>
  <b-card class="mt-3 ml-0 mr-0 mb-0">
    <h4 class="my-1 bold">Hourly Compensation</h4>
    <b-col class="border rounded-lg px-2">
      <h5 class="bold py-1 m-0">$10000</h5>
    </b-col>
  </b-card>
</template>
<script>
import { BCard, BCol } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BCol,
  },
};
</script>

<template>
  <div>
    <b-row>
      <b-col lg="12" md="12" sm="12">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center justify-content-between">
              <div>
                <h5 class="bold d-inline">Confirm your Interview Timing.</h5>
                <span class="ml-1">You can also request for reschedule</span>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-col lg="3" md="4" sm="6">
          <CandidateConfirmation
            id="777"
            name="Samia Khan"
            :img="require('@/assets/images/portrait/small/avatar-s-20.jpg')"
            date="2022-07-12"
            formatted-date="Tuesday, July 12, 2022"
            start-time="10:00:00"
            formatted-start-time="10:00 AM"
            end-time="11:00:00"
            formatted-end-time="11:00 AM"
          />
        </b-col>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <b-card>
          <div
            class="d-lg-flex d-md-flex justify-content-between align-items-center"
          >
            <div class="d-flex align-items-center justify-content-between">
              <div class="mr-2">
                <b-avatar
                  size="lg"
                  src="../../assets/images/avatars/1-small.png"
                />
              </div>
              <div>
                <p class="my-0">Assignment Manager</p>
                <h5 class="d-inline">Generic Name</h5>
                <span> is available to solve your queries</span>
              </div>
            </div>
            <div class="mt-lg-0 mt-md-0 mt-1">
              <b-button variant="outline-primary">
                Have a Conversation
              </b-button>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="10" md="5" lg="5" class="pt-2 pr-2 mt-0">
        <!-- requirement component -->
        <Requirements />
        <!-- hourly compensation  -->
        <HourlyCompensation />
      </b-col>
      <b-col sm="10" md="7" lg="7" class="pt-2 mt-0">
        <!-- assignment title component -->
        <AssignmentTitle />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BAvatar, BButton, BCard, BCol, BRow } from 'bootstrap-vue';
import AssignmentTitle from './AssignmentTitle.vue';
import CandidateConfirmation from './CandidateConfirmation.vue';
import HourlyCompensation from './HourlyCompensation.vue';
import Requirements from './Requirements.vue';

export default {
  components: {
    BRow,
    BCard,
    BAvatar,
    BButton,
    BCol,
    Requirements,
    HourlyCompensation,
    AssignmentTitle,
    CandidateConfirmation,
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.demo-inline-spacing {
  margin-top: -15px;
}
#present_job {
  overflow: overlay;
}
.title {
  margin-left: 5px;
}
</style>
